import React, { useContext, useEffect } from "react"
import Context from "../store/Context"

//React components imports
import Layout from "../components/Layout"
import ProfileForm from "../components/ProfileForm"
import PageTitle from "../components/PageTitle"

import ReservationText from "../components/ReservationText"

const ProfilePage = props => {
  const { dispatch } = useContext(Context)

  useEffect(() => {
    dispatch({ type: "SET_PATH", path: props.location.pathname })
  }, [dispatch, props.location.pathname])

  return (
    <Layout>
      {/* <PageTitle subtitle="Moje údaje">Rezervácia</PageTitle>
      <ProfileForm /> */}
      <ReservationText />
    </Layout>
  )
}

export default ProfilePage
